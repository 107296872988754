import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '@components/layout';

import PortableTextBlock from '@components/sanity/portableTextBlock';
import { SanityImage } from '@components/sanity/sanityImage';
import ArrowIcon from '@svg/arrow-r.svg';
import { PageMeta } from '@components/pageMeta';
const ProjectCard = ({ title, listingImage, shortDescription, slug }) => {
  return (
    <Link to={`/projects/${slug?.current}`}>
      <div className="aspect-w-1 aspect-h-1 relative w-full">
        <SanityImage image={listingImage} className="absolute w-full h-full object-cover" />
      </div>
      <h4 className="isH3 my-one">{title}</h4>
      <p className="text-[20px] font-serif">{shortDescription}</p>
      <span className="font-h-medium text-md flex items-center gap-4 mt-one">
        View Project <ArrowIcon className="w-[15px] text-solar-light fill-current" />
      </span>
    </Link>
  );
};

const ProjectsPortal = ({ data: { page, projects } }) => {
  return (
    <Layout pageBg={4} reverseText reverseHeader>
      <PageMeta {...page?.seo} />
      <div className="px-gutter mb-two lg:mb-32">
        <h1 className="isH1 max-w-4xl">{page?.title}</h1>
      </div>
      <div className="grid px-gutter lg:grid-cols-12 pb-32">
        <div className="lg:col-start-1 lg:col-end-4">
          <PortableTextBlock text={page?.text} className="lg:sticky lg:top-6" />
        </div>
        <div className="grid gap-4 md:grid-cols-2 md:gap-6 lg:gap-x-10 lg:gap-y-16 lg:col-start-5 lg:col-end-13">
          <h4 className="lg:col-span-2 text-md font-h-bold">Our Projects</h4>
          {projects?.nodes.map((post) => (
            <ProjectCard {...post} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ProjectsPortal;

export const pageQuery = graphql`
  query projectsPortalQuery {
    page: sanityProjectPortal {
      seo {
        ...SEO
      }
      text: _rawText(resolveReferences: { maxDepth: 10 })
      title
    }
    projects: allSanityProject(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        key: _key
        title
        shortDescription
        listingImage {
          ...ImageWithPreview
        }
        slug {
          current
        }
      }
    }
  }
`;
